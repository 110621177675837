<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="ach__relationsWrapper">
                <div class="ach_bannerWrapper">
                    <img src="https://img.i2soft.net/i2official-web/assets/images/relations/Achiev_back.png"/>
                    <!-- <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div> -->

                    <div class="bannerTextWrapper">
                        <span class="bannerText1">业绩报告</span>
                        <!-- <span class="bannerText3">深度行业解决方案</span> -->
                    </div>
                </div>
                <div class="announcementWrapper">
                    <div class="top_box">
                        <div class="pc_change">
                            <div>
                                <a @click.prevent="jump('anc')" v-bind:class="{'active':(staic == 'anc')}">公司公告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a  @click.prevent="jump('gov')" v-bind:class="{'active':(staic == 'gov')}">公司治理</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a v-bind:class="{'active':(staic == 'acv')}">业绩报告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('ser')" v-bind:class="{'active':(staic == 'ser')}">投资者服务</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div class="investor-report-s1">
                        <div class="wrapper">
                            <div class="block">
                                <div class="inner-tit gray">
                                    <h3 class="h3">年报</h3>
                                </div>
                                <div class="items">
                                    <div class="year">2019-2023</div>
                                    <div class="lists">
                                        <div class="list"
                                             v-for="annual in annualList"
                                             :key="annual.id"
                                        >
                                            <h3 class="h3">{{annual.name}}</h3>
                                            <div class="btns">
                                                <a :href="annual.to" target="_blank">在线预览</a>
                                                <a :href="annual.to" download="download" target="_blank">下载pdf</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-tit gray">
                                    <h3 class="h3">中报</h3>
                                </div>
                                <div class="items">
                                    <div class="year">2019-2024</div>
                                    <div class="lists">
                                        <div class="list"
                                             v-for="Inter in InterimList"
                                             :key="Inter.id"
                                        >
                                            <h3 class="h3">{{Inter.name}}</h3>
                                            <div class="btns">
                                                <a :href="Inter.to" target="_blank">在线预览</a>
                                                <a :href="Inter.to" download="download" target="_blank">下载pdf</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-tit gray">
                                    <h3 class="h3">季报</h3>
                                </div>
                                <div class="items">
                                    <div class="year">2019-2024</div>
                                    <div class="lists">
                                        <div class="list"
                                             v-for="Quart in QuarterlyList"
                                             :key="Quart.id"
                                        >
                                            <h3 class="h3">{{Quart.name}}</h3>
                                            <div class="btns">
                                                <a :href="Quart.to" target="_blank">在线预览</a>
                                                <a :href="Quart.to" download="download" target="_blank">下载pdf</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from "../../utils/index";

    export default {
        data() {
            return {
                isMobile: false,
                // 年报
                annualList: [],
                // 中报
                InterimList: [],
                // 季报
                QuarterlyList: [],
                datas: [],
                query_type:"",
                page: {
                    totle: '',
                    currentPage: 2,
                    pageSize: 8
                },
                staic: '',
            };
        },
        filters: {},
        created() {
            this.getachievent();
            this.query_type = this.$route.query.category;
            console.log("this.query_type",this.query_type);
            this.pg_change(this.query_type);
            window.scrollTo(0,540);
        },
        mounted() {
            this.isMobile = isMobile();
            window.addEventListener("scroll", this.getScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.getScroll);
        },
        methods: {
            // 跳转
            jump(type){
                if(type == "anc")  {
                    this.$router.push({ path: "/Announce", query: { category: type } });
                } else if(type == "gov"){
                    this.$router.push({ path: "/government", query: { category: type } });
                }else if(type == "ser"){
                    this.$router.push({ path: "/Service", query: { category: type } });
                }
            },
            // 跳转后tab标签的改变
            pg_change(type){
                console.log("static",type);
                this.staic = type;
            },
            // 获取业绩表
            getachievent(){
                // 年报业报
                this.$axios.get("/parameter/annualList").then((res) => {
                    this.annualList = res.data.data;
                    console.log("年报业报",this.annualList)
                })
                // 中报业报
                this.$axios.get("/parameter/InterimList").then((res) => {
                    this.InterimList = res.data.data;
                    console.log("中报业报",this.InterimList)
                })
                // 季报业报
                this.$axios.get("/parameter/QuarterlyList").then((res) => {
                    this.QuarterlyList = res.data.data;
                    console.log("季报业报",this.QuarterlyList)
                })
            },
            getScroll() {
                // this.indexNumTop = $("#index_num").offset().top;
                this.scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
                ) {
                    //设置滚动大于300时的执行内容
                    // console.log(
                    //   "document.documentElement.scrollTop=",
                    //   document.documentElement.scrollTop
                    // );
                    this.$store.commit("TO_AfterScroll");
                } else {
                    this.$store.commit("TO_AfterScroll_false");
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .ach__relationsWrapper {
        background-color: #ffffff;

        .ach_bannerWrapper {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .bannerTextWrapper {
                position: absolute;
                top: 45%;
                left: 45%;
                display: flex;
                flex-direction: column;

                .bannerText1 {
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText2 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 40px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText3 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Light;
                }
            }
        }

        .announcementWrapper {
            .top_box {
                background-color: rgba(246, 246, 246, 1);
                position: relative;
                padding: 1px 0px;
                height: 60px;
                top: -3px;
            }

            .pc_change {
                position: absolute;
                left: 60%;
                z-index: 1;
                cursor: default;
            }

            .pc_change div {
                margin-right: 10px;
                position: relative;
                margin-top: 6%;
                .active{
                    padding-bottom: 5px;
                    border-bottom: 2px solid #265ea2;
                    color: #265ea2;
                }
            }

            .pc_change a:nth-child(2n-1) {
                text-decoration: none;
                color: #000000;
                font-size: 15px;
                cursor: pointer;
            }

            .pc_change a:nth-child(2) {
                font-size: 15px;
            }

            .pc_change a:nth-child(2n-1):hover {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }

            .pc_change a:nth-child(2n-1):visited {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }
        }

        .mapWrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0px;
            .investor-report-s1 {
                padding: 6.25vw 0;
                .wrapper {
                    width: 80%;
                    max-width: 1560px;
                    margin: 0 auto;
                    position: relative;
                    z-index: 2;
                    .block:nth-of-type(n) {
                        margin-top: 0;
                        .inner-tit {
                            padding-bottom: 2vw;
                            border-bottom: 2px solid #333333;
                            h3 {
                                font-size: 26px;
                                color: #333333;
                            }
                        }
                        .items {
                            margin-top: 3.125vw;
                            position: relative;
                            .year {
                                font-size: 50px;
                                width: 15%;
                                margin-right: 2%;
                                margin-top: 20px;
                                display: inline-block;
                                vertical-align: top;
                                line-height: 1;
                                font-family: 'ziticqtezhanti';
                                word-wrap: break-word;
                                overflow: hidden;
                            }
                            .lists {
                                width: 80%;
                                display: inline-block;
                                vertical-align: top;
                                .list{
                                    position: relative;
                                    padding: 2vw 0;
                                    border-bottom: 1px solid #e6e6e6;
                                    .h3 {
                                        text-align: left;
                                        font-size: 20px;
                                        margin-right: 280px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        color: #333333;
                                    }
                                    .btns {
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        transform: translateY(-50%);

                                        a {
                                            text-decoration: none;
                                            margin-left: 20px;
                                            display: inline-block;
                                            vertical-align: top;
                                            text-align: center;
                                            border: 1px solid #e6e6e6;
                                            width: 120px;
                                            line-height: 40px;
                                            font-size: 15px;
                                        }
                                        a:link {
                                            color: #333333;
                                        }

                                        a:visited {
                                            color: #333333;
                                        }

                                        a:hover{
                                            color: white;
                                            background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

</style>


